/* eslint-disable no-console */
//https://javascript.info/script-async-defer
//https://javascript.info/onload-ondomcontentloaded
console.log('jsScriptLoader start');
window.Launchpad.deferJsTimer = window.Launchpad.deferJsTimer || 2750; // defaults to 2750

function loadScript(url, callback) {
	let script = document.createElement('script');
	script.type = 'text/javascript';
	script.async = false;
	script.defer = true;
	if (script.readyState) {  // only required for IE <9
		script.onreadystatechange = () => {
			if (script.readyState === 'loaded' || script.readyState === 'complete') {
				script.onreadystatechange = null;
				callback();
			}
		};
	} else {  //Others
		script.onload = () => {
			if (callback) {
				callback();
			}
		};
	}

	script.src = url;
	document.body.append(script);
}

function loadStyle(href) {
	let link = document.createElement('link');
	link.href = href;
	link.type = 'text/css';
	link.rel = 'stylesheet';
	link.media = 'screen,print';
	document.getElementsByTagName('head')[0].appendChild(link);
}

function scriptListLoader() {
	window.Launchpad.jsLoadList.forEach((x) => {
		loadScript(x.url, x.callback);
	});

	console.log('jsScriptLoader JS Loaded');

	let gtmtrigger = document.createElement('div');
	gtmtrigger.innerHTML = '&nbsp;';
	gtmtrigger.id = 'gtmloadtrigger';
	gtmtrigger.className = 'gtmloadtrigger';
	document.getElementsByTagName('body')[0].prepend(gtmtrigger);
}

function handlePageBuilderObserver() {
	let MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
	let obsConfig = { childList: true, characterData: true, attributes: true, subtree: true };
	let targetNodes = document.getElementById('main');

	let myObserver = new MutationObserver((mutationRecords) => {
		mutationRecords.forEach((mutation) => {
			if (typeof mutation.addedNodes === 'object') {
				let ktcEditableArea = mutation.target.classList.contains('ktc-editable-area');
				if (ktcEditableArea) {
					let targetLastChild = targetNodes.querySelector('.ktc-editable-area:last-child');

					if (mutation.target === targetLastChild) {
						scriptListLoader();
					}
					myObserver.disconnect();
				}
			}
		});
	});

	myObserver.observe(targetNodes, obsConfig);
}

window.loadJS = true;
function viewEvent(evt) {
	if (window.Launchpad.pageBuilderEditMode) {
		handlePageBuilderObserver();
	} else if (window.loadJS) {
		window.loadJS = false;
		scriptListLoader();		
	}
}

function deferJs() {
	console.log('jsScriptLoader loadscript_event');
	document.addEventListener('mousedown', viewEvent);
	document.addEventListener('mousemove', viewEvent);
	document.addEventListener('touchstart', viewEvent);
	document.addEventListener('scroll', viewEvent);
	document.addEventListener('keydown', viewEvent);

	setTimeout(() => {
		console.log('jsScriptLoader timed js');
		viewEvent();
	}, window.Launchpad.deferJsTimer);
	// 2.5 seconds is the period in which google includes its JS for page speed
	// increase to 2750 to ensure defer
}

function ready() {
	console.log('jsScriptLoader DOMContentLoaded');
}
document.addEventListener('DOMContentLoaded', ready);		

window.onload = () => { // can also use window.addEventListener('load', (event) => {
	console.log('jsScriptLoader onload');
};

if (window.Launchpad.deferJs) {	
	if (window.Launchpad.deferJsType === 'DOMContentLoaded') {	
		console.log('jsScriptLoader DeferJs DOMContentLoaded');	
		document.addEventListener('DOMContentLoaded', deferJs);		
	} else if (window.Launchpad.deferJsType === 'onload') {
		window.onload = () => { // can also use window.addEventListener('load', (event) => {
			console.log('jsScriptLoader DeferJs onload');
			deferJs();
		};
	} else {
		console.log('jsScriptLoader DeferJs default');
		deferJs();
	}
	console.log('jsScriptLoader done');
} else {
	viewEvent();
}
